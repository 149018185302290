import * as React from "react"
import CommonTemplate from "./common-template"
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"
import SEO from "../components/Seo/seo"
import {capitalize} from "lodash";
import OffPlans from "./off-plan-landing"
import FormTemplate from "./form-template"
import BrandLogo from "../images/logo.svg";
// markup
const DefaultTemplate = props => {
  const location = useLocation();  
  const GQLPage = props.data.glstrapi?.article
  const GQLModules = props.data.glstrapi?.article?.Modules
  //console.log("GQLPage",GQLPage)
  if (GQLPage.Layout === "OffPlan") {
    var offplanstate = props.location.state
  
    const urlParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlParams.entries());  
    let propertyval = offplanstate?.propertyval
    let minpriceVal = offplanstate?.minpriceVal
    let maxpriceVal = offplanstate?.maxpriceVal
    let minbedVal = offplanstate?.minbedVal
    let maxbedVal = offplanstate?.maxbedVal 
    if(params.type && !propertyval){
      propertyval = capitalize(params.type) ; 
    } 
    if(params.minpriceVal && !minpriceVal){
      minpriceVal = (params.minpriceVal) ; 
    } 
    if(params.maxpriceVal && !maxpriceVal){
      maxpriceVal = (params.maxpriceVal) ; 
    } 
    if(params.minbedroomVal && !minbedVal){
      minbedVal = (params.minbedroomVal) ; 
    } 
    if(params.maxbedroomVal && !maxbedVal){
      maxbedVal = (params.maxbedroomVal) ; 
    }  
    return (
      <>
        <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
          description={GQLPage.Meta_Description}
          image={BrandLogo}
        />
        <OffPlans GQLPage={GQLPage} GQLModules={GQLModules} completionval={offplanstate?.completionval} developerval={offplanstate?.developerval}propertyval={propertyval} minpriceVal={minpriceVal}maxpriceVal={maxpriceVal}minbedVal={minbedVal}maxbedVal={maxbedVal}bedVal={offplanstate?.bedVal}areasArr={offplanstate?.areasArr}  area={params.area} submitfilter={`true`} />
      </>
    )
  } else if (GQLPage.Layout === "Form") {
    return (
      <>
        <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
          description={GQLPage.Meta_Description}
          image={BrandLogo}
        />
        <FormTemplate GQLPage={GQLPage} GQLModules={GQLModules} />
      </>
    )
  } else {
    return (
      <>
        <SEO
          title={GQLPage.Meta_Title ? GQLPage.Meta_Title : GQLPage.Title}
          description={GQLPage.Meta_Description}
          image = {GQLPage.Title === "Home" || GQLPage.Banner_Overlay_Bg?.url ==="" ?  BrandLogo :   GQLPage.Banner_Overlay_Bg?.url}
        />
        <CommonTemplate GQLPage={GQLPage} GQLModules={GQLModules} />
      </>
    )
  }
}

export default DefaultTemplate
export const pageQuery = graphql`
  query DefaultQuery($articleId: ID!) {
    glstrapi {
      article(id: $articleId, publicationState: LIVE) {
        id
        Title
        Meta_Title
        Meta_Description
        Page_Class
        Layout
        Show_Search
        Banner_Reviews
        Banner_Title
        Multi_Banner_Title {
          Title
        }
        Banner_Content
        Select_Popular_Search
        Banner_Type
        imagetransforms
        Banner_Image {
          url
          alternativeText
        }
        Select_Contact {
          Designation
          Email
          URL
          id
          Phone
          Name
          imagetransforms
          Image {
            url
            url_sharp {
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  width: 180
                  quality: 80
                  layout: FIXED
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
          languages
        }
        Banner_Overlay_Bg {
          url
          alternativeText
        }
        Banner_Video_Link
        Banner_CTA {
          CTA_Label
          CTA_Link {
            Primary_URL
          }
          CTA_Secondary_Link
        }

        Modules {
          ... on GLSTRAPI_ComponentComponentsThumbnailGrid {
            id
            __typename
            Thumbnail_Grid_Heading
            Grid_List {
              id
              Grid_Thumbail_Text
              Grid_Thumbail_Title
              Grid_Thumbail_Link_Label
              Grid_Thumbail_Link {
                Primary_URL
              }
              Grid_Thumbail_Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 180
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
              Grid_Thumbail_Custom_Link
            }
          }
          ... on GLSTRAPI_ComponentComponentsGlobalModules {
            id
            __typename
            Select_Module
          }
          ... on GLSTRAPI_ComponentComponentsForms {
            id
            __typename
            Select_Form
          }
          ... on GLSTRAPI_ComponentComponentsFullWidthBlueBlock {
            id
            __typename
            Block_Video_URL
            Block_Heading
            Block_Content
            Block_Image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 560
                    height: 540
                    quality: 80
                    layout: FIXED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
            Block_CTA {
              CTA_Secondary_Link
              CTA_Label
              CTA_Link {
                Primary_URL
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsTileBlockCounter {
            __typename
            id
            Tile_Block_Slides {
              id
              Block_Title
              Block_Content
              Block_CTA {
                CTA_Label
                CTA_Secondary_Link
                CTA_Link {
                  Primary_URL
                }
              }
              Block_Video_URL
              Block_Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 560
                      height: 540
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsSelectAreaguides {
            id
            __typename
            Areaguides {
              id
              Title
              Tile_Image {
                url
              }
              URL
              imagetransforms
            }
            Module_Content
            Module_Heading
          }

          ... on GLSTRAPI_ComponentComponentsTileBlock {
            id
            __typename
            Tile_Title
            Tile_Image {
              url
              url_sharp {
                childImageSharp {
                  gatsbyImageData(
                    formats: WEBP
                    width: 560
                    quality: 80
                    layout: FIXED
                    transformOptions: { cropFocus: CENTER, fit: COVER }
                  )
                }
              }
            }
            Tile_Content
            Tile_CTA {
              CTA_Label
              CTA_Secondary_Link
              CTA_Link {
                Primary_URL
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsFaq {
            __typename
            id
            FAQ_List {
              Content
              Title
              id
            }
          }
          
          ... on GLSTRAPI_ComponentComponentsSelectFeaturedProperties {
            __typename
            id
            Listing_IDs
          }
          ... on GLSTRAPI_ComponentComponentsSelectReserevedProperties {
            __typename
            id
            Listing_IDs
          }

          ... on GLSTRAPI_ComponentComponentsVideoReviewBlocks {
            id
            __typename
            Review_Block_Items {
              Reviewer_Role
              Reviewer_Video_URL
              Reviewer_Name
              Reviewer_Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 440
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsTwoColumnContent {
            __typename
            id
            Left_Content
            Right_Content
            Select_Staffs {
              Designation
              URL
              Name
              Phone
              imagetransforms
              Image {
                url

                id
              }
              id
            }
            Team_Column_Heading
          }

          ... on GLSTRAPI_ComponentComponentsSubTiles {
            __typename
            id
            Module_Description
            Module_Title
            Tile_Image_Items {
              Description
              Title
              id
              Image {
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 320
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsInteriorDesignPackage {
            __typename
            id
            Module_Description
            Module_Title
            Module_Items {
              Description
              Price
              Title
              id
            }
          }

          ... on GLSTRAPI_ComponentComponentsStaticPage {
            id
            __typename
            Static_Content
          }

          ... on GLSTRAPI_ComponentComponentsLendersWeWorkWith {
            __typename
            Module_Description
            Module_Title
            id
            Module_Images {
              id
              Add_Image {
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 200
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsSubMenuModule {
            __typename
            id
            Sub_Menu_Listing {
              id
              Menu_Link {
                Label
                id
                Primary_URL
                Secondary_URL
                Show_In_Top_Nav
                Sorting
              }
              Tile_Image {
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      height: 200
                      quality: 80
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentsVideoBox {
            __typename
            id
            Video_URL
            Video_Thumbnail {
              url
            }
            imagetransforms
            Title
          }

          ... on GLSTRAPI_ComponentComponentsImageBlocks {
            id
            __typename
            Image_Block_Items {
              id
              Image_Block_Title
              Image_Block_CTA_Label
              Image_Block_Tile_Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(
                      formats: WEBP
                      width: 600
                      quality: 100
                      layout: FIXED
                      transformOptions: { cropFocus: CENTER, fit: COVER }
                    )
                  }
                }
              }
              Image_Block_CTA {
                Primary_URL
              }
            }
          }
          ... on GLSTRAPI_ComponentComponentsSidebarContentWithSidebarForm {
            __typename
            id
            Sidebar_Content
            Sidebar_Forms
            Sidebar_Title
            Form_Title
            _id
          }
          ... on GLSTRAPI_ComponentComponentsContentWithSidebar {
            id
            __typename
            Sidebar_Title
            Sidebar_Content
            Sidebar_Form
            Content_with_Image {
              id
              Title
              Image {
                alternativeText
                url
              }
              Content
            }
          }
        }
      }
    }
  }
`
