import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import $ from "jquery"
import axios from "axios"
import * as qs from "query-string"
import _ from "lodash";
import { Helmet } from "react-helmet"

const MySimpleForm = () => {
  return (
    <div className="form">
      <Helmet>
        <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
      </Helmet>
      <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00DKj000002Yhak" method="POST">
        <input type="hidden" name="oid" value="00DKj000002Yhak" />
        <input type="hidden" name="retURL" value="https://www.mcconeproperties.com/contact/" />
        <label htmlFor="first_name">First Name</label>
        <input id="first_name" maxLength="40" name="first_name" size="20" type="text" /><br />
        <label htmlFor="last_name">Last Name</label>
        <input id="last_name" maxLength="80" name="last_name" size="20" type="text" /><br />
        <label htmlFor="email">Email</label>
        <input id="email" maxLength="80" name="email" size="20" type="text" /><br />
        Subject:
        <select id="00NPW000000bhfV" name="00NPW000000bhfV" title="Subject">
          <option value="">--None--</option>
          <option value="I'm looking to Buy">I'm looking to Buy</option>
          <option value="I'm looking to Sell">I'm looking to Sell</option>
          <option value="Both">Both</option>
          <option value="Others">Others</option>
        </select><br />
        Website Comments:
        <textarea id="00NPW000000bhh7" name="00NPW000000bhh7" rows="3" wrap="soft"></textarea><br />
        Department:
        <select id="00NPW000000bhdt" name="00NPW000000bhdt" title="Department">
          <option value="">--None--</option>
          <option value="Sales">Sales</option>
          <option value="Lettings">Lettings</option>
          <option value="Both">Both</option>
          <option value="Other">Other</option>
        </select><br />
        <input type="submit" name="submit" />
      </form>
    </div>
  );
};

const ContactForm = (props) => (
  <MySimpleForm to_email_id={props.to_email_id} subject={props.subject} staff_name={props.staff_name} formtype={props.formtype} messagelabel={props.messagelabel} messageplaceholder={props.messageplaceholder} />
)

export default ContactForm