import React, { useRef, useState, useEffect } from "react"
import $ from "jquery";
import _ from "lodash"
import { isEmpty, filter, find, isArray, isString, get, indexOf } from "lodash"
// import { navigate } from "@reach/router"
// import { navigate as gatsbyNavigate } from "gatsby";
import { Container, Row, Col,  Form } from "react-bootstrap";
import "./SearchFilters.scss";
// import Slider from "react-slick";
// import { AnimationOnScroll } from "react-animation-on-scroll";
import Select from 'react-select';
import SearchListBox from "../predictive-search/search-multiareas";
import AreaListBox from "../SearchFilters/AreaList";
import { Developer,
  OffPlanPropertyType,
  priceminRangeResiSale,
  pricemaxRangeResiSale,
  priceminRangeResiRent,
  pricemaxRangeResiRent,
  bedroomsRange,
  propertyTypes,
  propertyTypesMap,
  completionRange,
  bedroomsMinRange, 
  bedroomsMaxRange,
  priceminRangeResiSaleGBP,
  pricemaxRangeResiSaleGBP,
  priceminRangeResiSaleEUR,
  pricemaxRangeResiSaleEUR,
  priceminRangeResiSaleUSD,
  pricemaxRangeResiSaleUSD,
  priceminRangeResiSaleINR,
  pricemaxRangeResiSaleINR,
  priceminRangeResiSaleRUB,
  pricemaxRangeResiSaleRUB
} from "../../components/common/propertyUtils";
import {  RefinementList } from "react-instantsearch-dom"
const SearchFilters = (props) => {
  const filters = useRef();
  const handleClick = () => {
    filters.current.classList.toggle("is-active");
  }

  const [areaVal, setAreaVal] = useState(props?.defaultArea);
  const [amenities, setAmenities] = useState('');
  const [areaRefineList, setAreaRefineList] = useState([]);
  const [areasArr, setAreasArr] = useState(props.areasArr1?props.areasArr1:[]);
  const [areasList, setAreasList] = useState([]);
  const [ismapview, setIsmapview] = useState(true);
  const [amenitesList, setAmenitesList] = useState([]);
  const [minpriceVal, setMinPriceVal] = useState(props.minpriceVal1?props.minpriceVal1:'');
  const [maxpriceVal, setMaxPriceVal] = useState(props.maxpriceVal1?props.maxpriceVal1:'');
  const [minbedroomVal, setMinBedroomVal] = useState(props.minbedVal1?props.minbedVal1:'');
  const [maxbedroomVal, setMaxBedroomVal] = useState(props.maxbedVal1?props.maxbedVal1:'');

  const [propertyval, setPropertyVal] = useState(props.propertyval1?props.propertyval1:'');
  const [developerval, setDeveloperVal] = useState(props.developerval1?props.developerval1:'');
  const [completionval, setCompletionVal] = useState(props.completionval1?props.completionval1:'');

  const [furnishval, setFurnishVal] = useState('');
  const [parkingval, setParkingVal] = useState('');
  const [viewingval, setViewingVal] = useState('');
  const [salesVal, setSalesVal] = useState('');
  const [bedVal, setBedVal] = useState('');
  const [sqftVal, setSqftVal] = useState('');
  const [minPriceList, setMinPriceList] = useState([]);
  const [maxPriceList, setMaxPriceList] = useState([]);
  const [minBedroomList, setMinBedroomList] = useState([]);
  const [maxBedroomList, setMaxBedroomList] = useState([]);

  const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0);
  const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0);
  const [selectedMinBedroomValue, setSelectedMinBedroomValue] = useState(0);
  const [selectedMaxBedroomValue, setSelectedMaxBedroomValue] = useState(0);
  const pyOptions = props.layout === 'map' ? propertyTypesMap : propertyTypes
  const defaultArea = "dubai";
  const handleMultiAreaFun = (val) => {
    let areas = [];
    if (isEmpty(find(areasArr, val))) {
      areas = [...areasArr]
      areas.push(val)
      setAreasArr(areas)
    }
  }

  const removeArea = (area, clear = false) => {
    if (!clear) {
      let prevAreas = areasArr.filter(x => x.slug !== area)
      setAreaVal('');
      setAreasArr(prevAreas);
    } else {
      setAreasArr([]);
      setAreaVal('');
      setAreasList([]);
    }
  }

  const curr = typeof window !== 'undefined' ? localStorage.getItem('currTo') : null
  //console.log("curr1", curr)
  const ChangeFunction = () => {
    if (curr === 'GBP') {
      setMinPriceList(priceminRangeResiSaleGBP)
      setMaxPriceList(pricemaxRangeResiSaleGBP)
    } else if (curr === 'EUR') {
      setMinPriceList(priceminRangeResiSaleEUR)
      setMaxPriceList(pricemaxRangeResiSaleEUR)
    } else if (curr === 'USD'){
      setMinPriceList(priceminRangeResiSaleUSD)
      setMaxPriceList(pricemaxRangeResiSaleUSD)
    } else if (curr === 'INR'){
      setMinPriceList(priceminRangeResiSaleINR)
      setMaxPriceList(pricemaxRangeResiSaleINR)
    } else if (curr === 'RUB'){
      setMinPriceList(priceminRangeResiSaleRUB)
      setMaxPriceList(pricemaxRangeResiSaleRUB)
    } else {
      setMinPriceList(priceminRangeResiSale)
      setMaxPriceList(pricemaxRangeResiSale)
    }
  }
  useEffect(() => {
    //console.log("curr2", curr)
    ChangeFunction()
    //setMinPriceVal("")
    //setMaxPriceVal("")
  }, [curr]);


  // Min price
  const onChangeMinPrice = (e) => {
    setSelectedMinPriceValue(e.value);
  }
  // Min price

  // Max Price
  const onChangeMaxPrice = (e) => {
    setSelectedMaxPriceValue(e.value);
  }
  // Max Price

  
  // Min bedroom
  const onChangeMinBedroom = (e) => {
    setSelectedMinBedroomValue(e.value);
  }
  // Min bedroom

  // Max bedroom
  const onChangeMaxBedroom = (e) => {
    setSelectedMaxBedroomValue(e.value);
  }
  // Max bedroom

  // Sales price list
  var minPrice = [];
  var maxPrice = pricemaxRangeResiSale.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

  priceminRangeResiSale.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPrice.push(items));

  if (minPrice.length > 0) {
  } else {
    minPrice.push(priceminRangeResiSale[0])
  }

  if (maxPrice.length > 0) {
  } else {
    maxPrice.push(pricemaxRangeResiSale[0])
  }
  // Sales price list

  // Lettings price list
  var minPriceLettings = [];
  var maxPriceLettings = pricemaxRangeResiRent.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

  priceminRangeResiRent.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPriceLettings.push(items));

  if (minPriceLettings.length > 0) {
  } else {
    minPriceLettings.push(priceminRangeResiRent[0])
  }

  if (maxPriceLettings.length > 0) {
  } else {
    maxPriceLettings.push(pricemaxRangeResiRent[0])
  }

  // Bedroom list
  var minBedroom = [];
  var maxBedroom = bedroomsMaxRange.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinBedroomValue)));

  bedroomsMinRange.map(items => parseInt(items.value) < parseInt(selectedMaxBedroomValue) && minBedroom.push(items));

  if (minBedroom.length > 0) {
  } else {
    minBedroom.push(bedroomsMinRange[0])
  }

  if (maxBedroom.length > 0) {
  } else {
    maxBedroom.push(bedroomsMaxRange[0])
  }
  // Bedroom list
  
  React.useEffect(() => {

   
    if (props.searchState?.refinementList?.search_areas) {
      let searchAreas = props.searchState.refinementList.search_areas;
      if (searchAreas !== defaultArea) {
        if (isString(searchAreas)) {
          searchAreas = [searchAreas]
        }
        setAreaRefineList(searchAreas);
      }
    }
    //console.log('aaaaaaaaaasearchState', props.searchState)
    if (props.searchState?.refinementList?.amenities) {
      let searchAmenities = props.searchState.refinementList.amenities;
      if (isString(searchAmenities)) {
        searchAmenities = [searchAmenities]
      }
      setAmenities(searchAmenities);
    }


  }, [props.searchState?.refinementList])

  useEffect(() => {
    // 
    if (_.isEmpty(minPriceList)) {
        //setMinPriceList(priceminRangeResiSale)
        //setMaxPriceList(pricemaxRangeResiSale)
    }
    if (_.isEmpty(minBedroomList)) {
      setMinBedroomList(bedroomsMinRange)
      setMaxBedroomList(bedroomsMaxRange)
  }
    //

  }, []);



  React.useEffect(() => {
    if (amenities.length > 0) {
      for (var i = 0; i < amenities.length; i++) {
        $('.amenities-refine input[type="checkbox"][value="' + amenities[i] + '"]').prop('checked', 'checked');
      }
    }
    if (props.searchState?.refinementList?.search_areas && areasList.length) {
      let search_areas = props.searchState.refinementList.search_areas;
      if (search_areas !== defaultArea) {
        let defaultRefineItems = []
        if (isString(search_areas)) {
          search_areas = [search_areas]
        }
        search_areas.map((item) => {
          let itemObj = find(areasList, ['slug', item])
          if (!isEmpty(itemObj)) {
            defaultRefineItems.push(itemObj)
          }
        });

        setAreasArr(defaultRefineItems);
        setAreaRefineList(search_areas);
      }

    }
  }, [areasList, props.searchState?.refinementList?.search_areas])

  const clickHandle = (e) => {
    var handleElement = document.getElementById('filter-price-range');
    if(handleElement.classList.contains('active') ){
      handleElement.classList.remove('active');
    } else {
    if(e != undefined) {
      const li = e.target.closest('li');
      li.closest('ul').querySelectorAll('li').forEach(item => {
        if (item !== li) {
          item.classList.remove('active');
        }
      })
      e.target.closest('li').classList.toggle('active');
    }
  }
  }

  const clickHandleBed = (e) => {
    var handleElement = document.getElementById('filter-bed-range');
    document.getElementById('filter-price-range').classList.remove('active');
    if(handleElement.classList.contains('active') ){
      handleElement.classList.remove('active');
    } else {
      //console.log("eeeeeeeeeee", e)
    if(e != undefined) {
      const li = e.target.closest('li');
      li.closest('ul').querySelectorAll('li').forEach(item => {
        if (item !== li) {
          item.classList.remove('active');
        }
      })
      e.target.closest('li').classList.toggle('active');
    }
  }
  }

  // Submit search

  //useEffect(()=>{
    //props.setAreaVal1(areaVal)
  //},[areaVal])
 
  const submitSearch = (e) => {
    advancedFilterClose();
    props.setAreaVal1(areaVal)
    var areaValue = []
    if (areasArr && areasArr.length > 0 ) {
      areasArr.map((a) => {
        if (a) {
             props?.OffPlanAreaList?areaValue.push(a.name):areaValue.push(a.name).replace(/\,.*/,'')
        }
      })
    }
    $('.price-filter-dropdown').removeClass("active");
    props.areasArr(areaValue);
    props.developerval(developerval);
    props.propertyval(propertyval);
    props.minpriceVal(minpriceVal);
    props.maxpriceVal(maxpriceVal);
    props.minbedVal(minbedroomVal);
    props.maxbedVal(maxbedroomVal);
    props.bedVal(bedVal);
    props.completionval(completionval);
    props.submitfilter("true");
  }

   // Advanced filter
   const advancedFilter = (e) => {
    $(".filter-search-overlay").addClass("active-filter")
    $("html").addClass("active-filter")
  }

  const advancedFilterClose = (e) => {
    $(".filter-search-overlay").removeClass("active-filter")
    $("html").removeClass("active-filter")
  }


  return (
    <React.Fragment>
      <div className="search-filters offplan-search-fiters">
        <div className="filter-top">
          <Container>
            <Row>
              <Col>
                <Form id="myForm" action="javascript:;" method="post" onSubmit={submitSearch}>
                  <div className="form-content">
                    <div className="input-wrap areas-list-wrap">
                      {/* <input type="text" placeholder="Location or reference"className="form-control"
                    /> */}
                      <div className="position-relative">
                        <SearchListBox OffPlanAreaList={props.OffPlanAreaList} areaVal={areaVal} setAreaVal={setAreaVal} setAreasList={setAreasList} handleMultiAreaFun={handleMultiAreaFun} autosuggestInputProps={{ className: 'form-control react-autosuggest__input refine-autosuggest-input' }} />
                      </div>
                    </div>


                    <div className="dropdowns-wrap">
                    <div className="select-option dropdown" onClick={(e) => clickHandle()}>
                        <Select
                          options={Developer}
                          placeholderValue={developerval ? developerval : "Developer"}
                          value={Developer.find(obj => obj.value === developerval)}
                          onChange={e => {setDeveloperVal(e.value);}}
                          isSearchable={false}
                          className="react-select light-color"
                          classNamePrefix="react-select"
                        >
                        </Select>
                      </div>
                     
                      <div className="select-option dropdown" onClick={(e) => clickHandle()}>
                        <Select
                          options={OffPlanPropertyType}
                          placeholderValue={propertyval ? propertyval : "Type"}
                          value={OffPlanPropertyType.find(obj => obj.value === propertyval)}
                          onChange={e => setPropertyVal(e.value)}
                          isSearchable={false}
                          className="react-select light-color"
                          classNamePrefix="react-select"
                        >
                        </Select>
                      </div>
                     
                      <div className="select-option dropdown">
                        {/* <Select options={priceOptions} placeholder="Price Range" className="react-select light-color" classNamePrefix="react-select" /> */}
                        <ul className="filter-list react-select">
                          <li className="price-filter-dropdown react-select light-color" id="filter-price-range">
                            <button type="button" className="react-select__control react-select__single-value react-select__dropdown-indicator" onClick={clickHandle}>Price Range</button>
                            <div className="filter-dropdown">
                              <div className="select">
                                <Select
                                  name="minprice"
                                  options={parseInt(selectedMaxPriceValue) === 0 ? minPriceList : minPrice}
                                  value={minPriceList.find(obj => obj.value === minpriceVal)}
                                  isSearchable={false}
                                  placeholder="Min Price"
                                  className="react-select light-color"
                                  classNamePrefix="react-select"
                                  onChange={e => { onChangeMinPrice(e); setMinPriceVal(e.value) }}
                                />
                              </div>
                              <div className="select">
                                <Select
                                  name="maxprice"
                                  options={parseInt(selectedMinPriceValue) === 0 ? maxPriceList : maxPrice}
                                  value={maxPriceList.find(obj => obj.value === maxpriceVal)}
                                  isSearchable={false}
                                  placeholder="Max Price"
                                  className="react-select light-color"
                                  classNamePrefix="react-select"
                                  onChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e.value) }}

                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="select-option dropdown">
                        <ul className="filter-list react-select">
                          <li className="price-filter-dropdown react-select light-color" id="filter-bed-range">
                            <button type="button" className="react-select__control react-select__single-value react-select__dropdown-indicator" onClick={clickHandleBed}>Bedrooms</button>
                            <div className="filter-dropdown">
                              <div className="select">
                                <Select
                                  name="minbedroom"
                                  options={minBedroomList}
                                  value={minBedroomList.find(obj => obj.value === minbedroomVal)}
                                  isSearchable={false}
                                  placeholder="Min Beds"
                                  className="react-select light-color"
                                  classNamePrefix="react-select"
                                  onChange={e => { onChangeMinBedroom(e); setMinBedroomVal(e.value) }}
                                />
                              </div>
                              <div className="select">
                                <Select
                                  name="maxbedroom"
                                  options={maxBedroomList}
                                  value={maxBedroomList.find(obj => obj.value === maxbedroomVal)}
                                  isSearchable={false}
                                  placeholder="Max Beds"
                                  className="react-select light-color"
                                  classNamePrefix="react-select"
                                  onChange={e => { onChangeMaxBedroom(e); setMaxBedroomVal(e.value) }}
                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      {/* <div className="select-option dropdown" onClick={(e) => clickHandle()}>

                        <Select
                          options={bedroomsRange}
                          placeholderValue={bedVal ? bedVal + " +" : "Bedrooms"}
                          value={bedroomsRange.find(obj => obj.value === bedVal)}
                          className="react-select light-color"
                          classNamePrefix="react-select"
                          onChange={e => setBedVal(e.value)}
                        >
                        </Select>

                      </div> */}
                      <div className="select-option dropdown" onClick={(e) => clickHandle()}>

                      <Select
                      options={completionRange}
                      placeholderValue={completionval ? completionval :"Completion"}
                      value={completionRange.find(obj => obj.value === completionval)}
                      className="react-select light-color"
                      classNamePrefix="react-select"
                      onChange={e => setCompletionVal(e.value)}
                      >
                      </Select>

                      </div>
<div className="d-none">
                        {(areaRefineList.length > 0) && (
                          <RefinementList
                            attribute="search_areas"
                            defaultRefinement={areaRefineList}
                          />
                        )}
                        {(amenities.length > 0) && (
                          <RefinementList
                            attribute="amenities"
                            defaultRefinement={amenities}
                          />
                        )}
                      </div>

                    </div>
                    <div className="btns-wrap">                  
                    <button type="button" className="btn btn-secondary mobile-filter-btn" onClick={advancedFilter}>
                        Filters
                        <span className="wrap-icon">
                          <i className="icon-filter"></i>
                        </span>
                      </button>                

                      <button type="submit" className="btn">
                        Search
                        <span className="wrap-icon">
                          <i className="icon-search"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
          <div className="filter-search-overlay">
            <div className="filter-close">
              <a href="javascript:void(0)" onClick={advancedFilterClose}><i className="icon-search-close"></i></a>
            </div>
            <div className="filter-wrapper">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={6} lg={8} md={10}>
                    <div className="position-relative">
                      <SearchListBox areaVal={areaVal} setAreaVal={setAreaVal} setAreasList={setAreasList} handleMultiAreaFun={handleMultiAreaFun} autosuggestInputProps={{ className: 'form-control react-autosuggest__input refine-autosuggest-input' }} />
                    </div>
                    <AreaListBox areas={areasArr} removeArea={removeArea}  />

                    <div className="select-option dropdown" onClick={(e) => clickHandle()}>
                    <span className="overlay-field-label">Developer</span>
                        <Select
                          options={Developer}
                          placeholderValue={developerval ? developerval : "Developer"}
                          value={Developer.find(obj => obj.value === developerval)}
                          onChange={e => {setDeveloperVal(e.value);}}
                          isSearchable={false}
                          className="react-select light-color"
                          classNamePrefix="react-select"
                        >
                        </Select>
                      </div>
                     
                      <div className="select-option dropdown" onClick={(e) => clickHandle()}>
                      <span className="overlay-field-label">Property Type</span>
                        <Select
                          options={OffPlanPropertyType}
                          placeholderValue={propertyval ? propertyval : "Type"}
                          value={OffPlanPropertyType.find(obj => obj.value === propertyval)}
                          onChange={e => setPropertyVal(e.value)}
                          isSearchable={false}
                          className="react-select light-color"
                          classNamePrefix="react-select"
                        >
                        </Select>
                      </div>
                     
                      <div className="select-option dropdown mobile-only-type">
                      <span className="overlay-field-label">Price</span>
                        {/* <Select options={priceOptions} placeholder="Price Range" className="react-select light-color" classNamePrefix="react-select" /> */}
                        <ul className="filter-list react-select">
                          <li className="price-filter-dropdown react-select light-color" id="filter-price-range">
                            <button type="button" className="react-select__control react-select__single-value react-select__dropdown-indicator" onClick={clickHandle}>Price Range</button>
                            <div className="filter-dropdown">
                              <div className="select">
                                <Select
                                  name="minprice"
                                  options={minPriceList}
                                  value={minPriceList.find(obj => obj.value === minpriceVal)}
                                  isSearchable={false}
                                  placeholder="Min Price"
                                  className="react-select light-color"
                                  classNamePrefix="react-select"
                                  onChange={e => { onChangeMinPrice(e); setMinPriceVal(e.value) }}
                                />
                              </div>
                              <div className="select">
                                <Select
                                  name="maxprice"
                                  options={maxPriceList}
                                  value={maxPriceList.find(obj => obj.value === maxpriceVal)}
                                  isSearchable={false}
                                  placeholder="Max Price"
                                  className="react-select light-color"
                                  classNamePrefix="react-select"
                                  onChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e.value) }}

                                />
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="select-option dropdown" onClick={(e) => clickHandle()}>
                      <span className="overlay-field-label">Bedrooms</span>
                        <Select
                          options={bedroomsRange}
                          placeholderValue={bedVal ? bedVal + " +" : "Bedrooms"}
                          value={bedroomsRange.find(obj => obj.value === bedVal)}
                          className="react-select light-color"
                          classNamePrefix="react-select"
                          onChange={e => setBedVal(e.value)}
                        >
                        </Select>

                      </div>
                      <div className="select-option dropdown" onClick={(e) => clickHandle()}>
                      <span className="overlay-field-label">Completion</span>
                      <Select
                      options={completionRange}
                      placeholderValue={completionval ? completionval :"Completion"}
                      value={completionRange.find(obj => obj.value === completionval)}
                      className="react-select light-color"
                      classNamePrefix="react-select"
                      onChange={e => setCompletionVal(e.value)}
                      >
                      </Select>

                      </div>

                    <div className="overlay-submit-actions">
                    <button type="submit" className="btn" onClick={submitSearch}>
                    Update Results
                    </button>
                    <button onClick={advancedFilterClose} className="btn btn-secondary">
                    Cancel
                    </button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <div className="area--list results--area-list offplan--area--list">
        <Container>
          <Row>
            <Col>
              <AreaListBox areas={areasArr} removeArea={removeArea} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchFilters;
